import { FRENCH_LOCALE_ID, GERMAN_LOCALE_ID, JAPANESE_LOCALE_ID, PORTUGEESE_LOCALE_ID, SPANISH_LOCALE_ID } from "/constants/localization";
const SPANISH_HELP_CENTER_LINK = "//support.monday.com/hc/es";
const FRENCH_HELP_CENTER_LINK = "//support.monday.com/hc/fr";
const PORTUGEESE_HELP_CENTER_LINK = "//support.monday.com/hc/es";
const SPANISH_BLOG_LINK = "//monday.com/blog/es/";
const PORTUGEESE_BLOG_LINK = "//monday.com/blog/pt/";
const FRENCH_BLOG_LINK = "//monday.com/blog/fr/";
const GERMAN_BLOG_LINK = "//monday.com/blog/de/";
const JAPANESE_BLOG_LINK = "//monday.com/blog/ja/";
const SPANISH_WORK_OS_LINK = "//monday.com/blog/es/work-os/";
const PORTUGEESE_WORK_OS_LINK = "//monday.com/blog/pt/work-os-uma-plataforma-para-o-futuro-do-trabalho/";
const FRENCH_WORK_OS_LINK = "//monday.com/blog/fr/le-work-os-lavenir-du-travail/";
export const LocalizedResourcesLinks = {
    [SPANISH_LOCALE_ID]: {
        "header.blog": {
            href: SPANISH_BLOG_LINK
        },
        "footer.blog": {
            href: SPANISH_BLOG_LINK,
            openInNewTab: true
        },
        "footer.helpCenter": {
            href: SPANISH_HELP_CENTER_LINK,
            openInNewTab: true
        }
    },
    [PORTUGEESE_LOCALE_ID]: {
        "header.blog": {
            href: PORTUGEESE_BLOG_LINK
        },
        "footer.blog": {
            href: PORTUGEESE_BLOG_LINK,
            openInNewTab: true
        },
        "footer.helpCenter": {
            href: PORTUGEESE_HELP_CENTER_LINK,
            openInNewTab: true
        }
    },
    [FRENCH_LOCALE_ID]: {
        "header.blog": {
            href: FRENCH_BLOG_LINK
        },
        "footer.blog": {
            href: FRENCH_BLOG_LINK,
            openInNewTab: true
        },
        "footer.helpCenter": {
            href: FRENCH_HELP_CENTER_LINK,
            openInNewTab: true
        }
    },
    [GERMAN_LOCALE_ID]: {
        "header.blog": {
            href: GERMAN_BLOG_LINK
        },
        "footer.blog": {
            href: GERMAN_BLOG_LINK,
            openInNewTab: true
        }
    },
    [JAPANESE_LOCALE_ID]: {
        "header.blog": {
            href: JAPANESE_BLOG_LINK
        },
        "footer.blog": {
            href: JAPANESE_BLOG_LINK,
            openInNewTab: true
        }
    }
};
const DEFAULT_LINKS = {
    "header.blog": {
        href: "//monday.com/blog"
    }
};
export const LocalizedOurProductFooterLinksV2 = {
    [SPANISH_LOCALE_ID]: {
        "footer.workOS": SPANISH_WORK_OS_LINK
    },
    [PORTUGEESE_LOCALE_ID]: {
        "footer.workOS": PORTUGEESE_WORK_OS_LINK
    },
    [FRENCH_LOCALE_ID]: {
        "footer.workOS": FRENCH_WORK_OS_LINK
    }
};
export const getLocalizedLink = (link, localeId)=>{
    var _DEFAULT_LINKS_link;
    const localizedLinks = LocalizedResourcesLinks[localeId];
    return localizedLinks && localizedLinks[link] ? localizedLinks[link].href : ((_DEFAULT_LINKS_link = DEFAULT_LINKS[link]) === null || _DEFAULT_LINKS_link === void 0 ? void 0 : _DEFAULT_LINKS_link.href) || link;
};
